.container {
  position: absolute;
  bottom : 35px;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.labelContainer {
  position : relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  text-align: center;
  background-color: #0668390A;
  padding: 0px 16px;
  margin-right: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.textStyle {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: #23397B;
}

.arbutton {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 40px;
  width: 172px;
  border: transparent;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  pointer-events: all;
  background-color: #113b77;
}

.atag {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.btntext {
  display: flex;
  color: white;
  margin-left: 12px;
  padding-right: 12px;
  font-size: 15px;
  white-space: nowrap;
}

.arbutton:hover {
  background-color: #3f51b5;
  cursor: pointer;
}

.arbutton:focus {
  outline: none;
}

/* @media only screen and (max-width: 959px) {
  .arbutton {
    display: none;
  }
} */