.spbutton {
  display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 180px;
    height: 40px;
    border: transparent;
    border-radius: 10px;
    pointer-events: all;
    background-color: #113b77;
    color: white;
    font-size: 16px;
}

.spbutton:focus {
  outline: none;
}

@media only screen and (min-width: 960px) {
  .spbutton{
    display:none;
  }
}
