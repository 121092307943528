.container {
  position: absolute;
  top: 90px;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zobutton {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 40px;
  width:fit-content;
  border: transparent;
  padding: 10px;
  border-radius: 10px;
  pointer-events: all;
  background-color: #113b77;
}

.btntext {
  display: flex;
  color: white;
  margin-left: 6px;
  font-size: 15px;
  white-space: nowrap;
}

.zobutton:focus {
  outline: none;
}

@media only screen and (min-width: 960px) {
  .container{
    display:none;
  }
}
