.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  width:100%;
}

.title {
  font-size: 32px;
  font-family: 'Open Sans', sans-serif;
  color: #113b77;
  font-weight: 800;
  margin: 0 0 32px;
  white-space: nowrap;
}

.description {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  color: #1b5bc2;
  margin: 4px;
}

.formStyle {
  margin-top: 20px;
  padding: 0 240px;
  width:100%;
  display: flex; 
  flex-direction: column;
  justify-content: 'center';
  align-items: 'center';
}

.inputDiv {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.textField {
  width:100%;
  border: 1px solid #1b5bc2;
  border-radius: 9px;
  padding: .5rem 2.5rem;
  color: #1b5bc2;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: italic;
}

.textField:focus {
  outline: none;
  border: 2px solid #1b5bc2;
  border-radius: 9px;
}

.inputLogo {
  width: 20px;
  height:20px;
  color:#1b5bc2;
  position: absolute;
  top: .7rem;
  left:10px;
}

.labelStyle {
  position: absolute;
  top: .5rem;
  left: 2.5rem;
  font-style: italic;
  color: #1b5bc2;
  transition: all .3s ease;
  cursor: text;
  font-size: 14.4px;
  pointer-events: none;
}

.inputDiv input:focus +  .labelStyle{
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateX(10px);
}

.inputDiv textarea:focus +  .labelStyle{
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateX(10px);
}

.errormsg {
  color: red;
  font-size: 14.4px;
  text-align: right;
  font-family: 'Open Sans', sans-serif;
}
.errorinput {
  border: 2px solid red;
  width:100%;
  border-radius: 9px;
  padding: .5rem 2.5rem;
  color: red;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: italic;
  margin-bottom: .5rem;
}

.errorinput:focus {
  outline: none;
}

.errorColor {
  color:red;
}

.submitBtn {
  position: relative;
  min-width: 200px;
  text-align: center;
  border: none;
  padding: .7rem 3rem;
  background-color: #1b5bc2;
  color: #fff;
  border-radius: 9px;
  display: inline-block;
  margin-bottom: 1rem;
  font-weight: 700;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
}

.closeBtn{
  position: absolute;
  top: -25px;
  right: -25px;
  cursor: pointer;
  background-color: red;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeBtn::after, .closeBtn::before {
  content: '';
  width: 1px;
  height: 60%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) rotate(-45deg);
  transition: all .1s ease;
}

.closeBtn::before {
  transform: translate(-50%,-50%) rotate(45deg);
}

.closeBtn:hover::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  transform: scale(1.1) translate(-50%,-50%) rotate(45deg);
  transition: all .1s ease;
}

.closeBtn:hover::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  transform: scale(1.1) translate(-50%,-50%) rotate(-45deg);
  transition: all .1s ease;
}

@media only screen and (max-width: 959px) {
  .formStyle {
    padding: 0 0;
  }

  .errormsg {
    text-align: center;
  }
}

@media only screen and (max-width: 360px) {
  .container {
    padding: 20px;
  }

  .formStyle {
    padding: 0 0;
  }

  .errormsg {
    text-align: center;
  }

  .title {
    font-size: 26px;
    font-weight: 800;
    margin: 0 0 5px;
  }
  
  .description {
    font-size: 12px;
    margin: 2px;
  }

  textarea {
    height: 6em;
  }

}