.container {
  width: 100px;
  height: 40px;
  text-align: center;
  background-color: rgba(0,0,0,.3);
  border-radius: 50px;
  padding-top: 6px;
}

.handhide {
  display: none;
}

.handstyle {
  width: 30px;
  height: auto;
  z-index: 2;
  margin: 0 auto 0 30px;
  animation-name: handAnim;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes handAnim {
  0% {
    margin-left: 0;
}
25% {
    margin-left: 60px;
}
50% {
    margin-left: 0;
}
75% {
    margin-left: -60px;
}
100% {
    margin-left: 0;
}
}

@media only screen and (min-width: 768px) {
  .container {
    display: none;
  }
  }