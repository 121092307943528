* {
    box-sizing: border-box;
}

html,
body,
#root {
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    overscroll-behavior: none;
}

html {
    overflow: hidden;
    height: 100%;
    font-size: 16px;
}

@media screen and (min-width: 600px) {
    html {
        font-size: 16px;
    }
}

@media screen and (min-width: 960px) {
    html {
        font-size: 16px;
    }
}

@media screen and (min-width: 1280px) {
    html {
        font-size: 16px;
    }
}

@media screen and (min-width: 1439px) {
    html {
        font-size: 20px;
    }
}

@media screen and (min-width: 1920px) {
    html {
        font-size: 20px;
    }
}

body {
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: auto;
}

.App {
    height: 100%;
}

.canvas-container {
    z-index: 0;
    position: absolute!important;
    top: 0;
    perspective: 1px;
}

canvas {
    width: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
}
  