.container {
  position: absolute;
  top: 108px;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.labelContainer {
  position : relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  text-align: center;
  background-color: #0668390A;
  padding: 0px 16px;
  margin-right: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.textStyle {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #0f3871;
}
.spbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: transparent;
    border-radius: 100%;
    pointer-events: all;
    background-color: #113b77;
  }
  
  .spbutton:focus {
    outline: none;
  }

  @media only screen and (max-height: 650px) {
    .container {
      position: absolute;
      top: 96px;
      right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .labelContainer {
      position : relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      text-align: center;
      background-color: #0668390A;
      padding: 0px 16px;
      margin-right: 8px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
    .textStyle {
      font-size: 14px;
      font-family: Arial, Helvetica, sans-serif;
      color: #0f3871;
    }
    .spbutton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border: transparent;
      border-radius: 100%;
      pointer-events: all;
      background-color: #113b77;
    }
  }

  @media only screen and (min-width: 960px) {
    .container {
      display:none;
    }
  }