.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.metricbutton {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: fit-content;
  height: 40px;
  padding: 10px;
  border: transparent;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  pointer-events: all;
  background-color: #113b77;
  color: white;
  font-size: 15px;
}

.metricbutton:hover {
  background-color: #3f51b5;
  cursor: pointer;
}

.metricbutton:focus {
  outline: none;
}

.btnlogo {
  color: white;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

@media only screen and (min-width: 640px) {
  .metricbutton {
    font-size:20px;
  }

  .btnlogo {
    color: white;
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 960px) {
  .container {
    display:none;
  }
}