.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.secondbox {
  display: flex;
  justify-content: center;
}

.subtitle {
  color: #113b77;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 20px;
  font-weight:600;
  white-space: nowrap;
}