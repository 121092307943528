.container {
  bottom: 256px;
  right:2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1vh;
}

.labelContainer {
  position : relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  text-align: center;
  background-color: #0668390A;
  padding: 0px 16px;
  margin-right: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.textStyle {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: #23397B;
}

.spbutton {
    position: relative;
    width: 40px;
    height: 40px;
    border: transparent;
    border-radius: 100%;
    pointer-events: all;
    background-color: #113b77;
  }
  
  .spbutton:hover {
    background-color: #3f51b5;
    cursor: pointer;
  }
  
  .spbutton:focus {
    outline: none;
  }

  @media only screen and (max-width: 959px) {
    .container{
      display:none;
    }
  
    .labelContainer {
      display:none;
    }
  }