.container {
  position:absolute;
  bottom : 0;
  right: 0;
  left: 0;
  max-height: 8%;
  margin:auto;
}

.navcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  pointer-events: all;
  pointer-events: all;
}

.btntext {
  color: #113b77;
}

@media only screen and (min-width: 960px){
  .container {
    display: none;
  }
}