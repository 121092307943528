@font-face {
  font-family: "GRI-Font-heading";
  font-style: normal;
  src: local("GRI-Font-heading"),
    url(./res/fonts/Akrobat-Regular.otf) format("opentype");
}

@font-face {
  font-family: "GRI-Font-regular";
  font-style: normal;
  src: local("GRI-Font-regular"),
    url(./res/fonts/Calibri-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "GRI-Font-content";
  font-style: normal;
  src: local("GRI-Font-content"),
    url(./res/fonts/Calibri-Light.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title-container {
  text-align: center;
  background: transparent;
  z-index: 2;
}

.page-title {
  font-size: 30px;
  margin-bottom: 8px;
  font-family: 'Open Sans', sans-serif;
  color: #113b77;
}

.tire-title {
  font-size: 4vh;
  margin-bottom: 3vh;
  font-family: 'Open Sans', sans-serif;
  color: #113b77;
}

.agri-title {
  font-family: 'Open Sans', sans-serif;
  /* text-shadow: 0 0 15px #27ae6d, 0 0 15px #27ae6d, 0 0 15px #27ae6d, 0 0 15px #27ae6d, 0 0 15px #27ae6d, 0 0 15px #27ae6d, 0 0 15px #27ae6d; */
}

.breadcrumb {
  font-size: 2vh;
  text-decoration: none;
  display: block;
  color: #113b77
}

.paragraph-sm {
  font-size: 10px;
}

.paragraph {
  font-size: 12px;
  color: #113b77;
}

.paragraph-big {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 20px;
  color: #113b77;
}

.paragraph-heavy {
  font-family: 'Open Sans', sans-serif;
}

.subtitle {
  font-size: 15px;
}

@media only screen and (min-width: 600px) {
  
  .page-title {
    font-size: 40px;
    margin-bottom: 15px;
  }
  
  .breadcrumb {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 18.72px;
  }
  
  .paragraph-sm {
    font-size: 10px;
  }
  
  .paragraph {
    font-size: 16px;
  }
  
  .paragraph-big {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  
  .subtitle {
    font-size: 18.72px;
  }
  
}

@media only screen and (min-width: 960px) {
  .page-title {
    font-size: 48px;
  }

  .paragraph-big {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .page-title {
    font-size: 48px;
  }

  .paragraph-big {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1920px) {
  .page-title {
    font-size: 48px;
  }

  .paragraph-big {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}