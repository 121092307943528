.container {
  background: url(../res/griDark.png) no-repeat center center fixed;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.box {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  color: transparent;
  margin-right: 20px;
}
